import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    useLocale,
    SimpleList,
    ReferenceField,
} from "react-admin";
import { useMediaQuery } from "@material-ui/core";

export const SubscriptionList = (props) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const locale = useLocale();

    return (
        <List
            style={{ marginTop: -5, marginLeft: -10, marginRight: -10 }}
            {...props}
            perPage={isSmall ? 8 : 25}
            sort={{ field: "organisation_id", order: "ASC" }}
        >
            {isSmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(record) => record.id}
                    secondaryText={(record) => `${record.service}`}
                />
            ) : (
                <Datagrid locales={locale} rowClick="show">
                    <ReferenceField
                        link={"show"}
                        source="organisation_id"
                        reference="organisation"
                    >
                        <TextField source={"name"} />
                    </ReferenceField>
                    <TextField source={"service"} />
                </Datagrid>
            )}
        </List>
    );
};

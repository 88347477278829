import React, { useState, useCallback } from "react";
import {
    List,
    Edit,
    SimpleForm,
    TextInput,
    Datagrid,
    TextField,
    DateField,
    useLocale,
    TopToolbar,
    ShowButton,
    Filter,
    ReferenceField,
    SimpleList,
    ReferenceInput,
    AutocompleteInput,
    Create,
    required,
    useNotify,
    Toolbar,
    useRedirect,
    TabbedShowLayout,
    Tab,
    Show,
    TabbedShowLayoutTabs,
} from "react-admin";

import { DateTimeInput, ListButton } from "ra-ui-materialui";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { useMediaQuery } from "@material-ui/core";
import { Cancel, CheckCircle, Add } from "@material-ui/icons";
import AddAddress from "../components/AddAddress";
import AddZone from "../components/AddZone";
import { gql, useMutation } from "@apollo/client";
import { Button, CircularProgress } from "@mui/material";
import dayjs from "dayjs";

const MUTATION_INSERT_SITE = gql`
    mutation insert_site(
        $name: String!
        $created: timestamptz!
        $shortName: String!
        $updated: timestamptz!
        $orgId: Int!
        $facilitySettings: String
        $suburb: String!
        $street: String!
        $longitude: float8!
        $latitude: float8!
        $code: String!
        $city: String!
        $country: String!
        $zoneShortName: String
        $zoneName: String
    ) {
        insert_site_one(
            object: {
                name: $name
                short_name: $shortName
                updated: $updated
                created: $created
                organisation_id: $orgId
                facility_page_settings: $facilitySettings
                address: {
                    data: {
                        suburb: $suburb
                        street: $street
                        longitude: $longitude
                        latitude: $latitude
                        country: $country
                        code: $code
                        city: $city
                    }
                }
                zones: {
                    data: {
                        created: $created
                        updated: $updated
                        short_name: $zoneShortName
                        name: $zoneName
                    }
                }
            }
        ) {
            id
            name
        }
    }
`;

const SiteEditActions = ({ basePath, data }) => (
    <TopToolbar>
        <ListButton basePath={basePath} label="Back" icon={<ChevronLeft />} />
        <ShowButton basePath={basePath} record={data} />
    </TopToolbar>
);

const SiteFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search by site name" source="name" alwaysOn />
    </Filter>
);

const ZoneFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search by site name" source="name" alwaysOn />
    </Filter>
);

const ZoneList = (props) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const locale = useLocale();

    return (
        <List
            style={{ marginTop: 20 }}
            {...props}
            basePath={"/zone"}
            resource={"zone"}
            perPage={isSmall ? 8 : 25}
            sort={{ field: "name", order: "DESC" }}
            filters={<ZoneFilter />}
        >
            {isSmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(record) => record.id}
                    secondaryText={(record) => `${record.name}`}
                />
            ) : (
                <Datagrid locales={locale} rowClick="show">
                    <TextField source={"name"} />
                    <TextField source={"short_name"} />
                    <ReferenceField
                        link={"show"}
                        source="site_id"
                        reference="site"
                    >
                        <TextField source={"name"} />
                    </ReferenceField>
                </Datagrid>
            )}
        </List>
    );
};

export const SiteShow = (props) => {
    console.log(props);
    return (
        <Show {...props}>
            <TabbedShowLayout
                tabs={<TabbedShowLayoutTabs variant="scrollable" {...props} />}
            >
                <Tab label="summary">
                    <TextField source="name" />
                    <TextField source="short_name" />
                    <ReferenceField
                        source="organisation_id"
                        reference="organisation"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField source="address_id" reference="address">
                        <TextField source="street" />
                    </ReferenceField>
                </Tab>
                <Tab label="Zones">
                    <ZoneList
                        sort={{ field: "name", order: "ASC" }}
                        filter={{ site_id: props.id }}
                    />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export const SiteCreate = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const [siteAddress, setSiteAddress] = useState();
    const [formData, setFormdata] = useState({
        siteName: "",
        shortName: "",
        settings: "",
    });
    const [newZone, setNewZone] = useState();
    const [selectedOrg, setSelectedOrg] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [addSite] = useMutation(MUTATION_INSERT_SITE);

    const handleInputChange = (e) => {
        e.persist();
        setFormdata((inputs) => ({
            ...inputs,
            [e.target.id]: e.target.value,
        }));
    };

    const handleClick = useCallback(() => {
        addSite({
            variables: {
                name: formData.siteName,
                shortName: formData.shortName,
                orgId: selectedOrg,
                created: dayjs().format(),
                updated: dayjs().format(),
                facilitySettings: formData.settings,
                street: siteAddress.address1,
                city: siteAddress.city,
                suburb: siteAddress.address2,
                code: siteAddress.postcode,
                country: "New Zealand",
                latitude: parseFloat(siteAddress.x),
                longitude: parseFloat(siteAddress.y),
                zoneName: newZone.name,
                zoneShortName: newZone.short_name,
            },
        })
            .then((r) => {
                console.log(r);
                notify(`Site: ${r.data.insert_site_one.name} added`);
                redirect("/site");
            })
            .catch((e) => {
                notify(`Error adding site: ${e}`);
            });
    }, [
        addSite,
        redirect,
        formData,
        notify,
        selectedOrg,
        siteAddress,
        newZone,
    ]);

    const PostEditToolbar = () => (
        <Toolbar>
            <Button
                variant="contained"
                startIcon={<Add />}
                disabled={
                    !selectedOrg ||
                    formData.siteName === "" ||
                    formData.shortName === "" ||
                    isLoading ||
                    !newZone ||
                    !siteAddress
                }
                onClick={handleClick}
            >
                ADD SITE
            </Button>
        </Toolbar>
    );

    return (
        <Create {...props} redirect="edit">
            <SimpleForm toolbar={<PostEditToolbar />}>
                <TextInput
                    fullWidth
                    source="name"
                    validate={[required()]}
                    id="siteName"
                    onChange={handleInputChange}
                />
                <TextInput
                    fullWidth
                    source="short_name"
                    validate={[required()]}
                    id="shortName"
                    onChange={handleInputChange}
                />
                <ReferenceInput
                    source="organisation_id"
                    fullWidth
                    reference="organisation"
                    validate={[required()]}
                    onSelect={(e) => setSelectedOrg(e.id)}
                    filterToQuery={(searchText) => ({ name: searchText })}
                >
                    <AutocompleteInput optionText={"name"} />
                </ReferenceInput>
                {isLoading && <CircularProgress />}
                {!siteAddress ? (
                    <AddAddress
                        getAddy={setSiteAddress}
                        loading={setIsLoading}
                    />
                ) : (
                    <div style={{ width: "100%", paddingBottom: 4 }}>
                        Selected Address: {siteAddress.full}
                    </div>
                )}
                <TextInput
                    fullWidth
                    source="facility_page_settings"
                    id="settings"
                    onChange={handleInputChange}
                />
                {newZone && <div>Zones: {newZone.name}</div>}
                {!newZone && <AddZone setZone={setNewZone} />}
            </SimpleForm>
        </Create>
    );
};

export const SiteList = (props) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const locale = useLocale();

    return (
        <List
            style={{ marginTop: -5, marginLeft: -10, marginRight: -10 }}
            {...props}
            perPage={isSmall ? 8 : 25}
            sort={{ field: "name", order: "DESC" }}
            filters={<SiteFilter />}
        >
            {isSmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(record) => record.id}
                    secondaryText={(record) =>
                        `${record.first_name} ${record.last_name}`
                    }
                    tertiaryText={(record) =>
                        record.active ? <CheckCircle /> : <Cancel />
                    }
                />
            ) : (
                <Datagrid locales={locale} rowClick="show">
                    <TextField source={"name"} />
                    <TextField source={"short_name"} />
                    <ReferenceField
                        link={"show"}
                        source="address_id"
                        reference="address"
                    >
                        <TextField source={"street"} />
                    </ReferenceField>
                    <ReferenceField
                        link={"show"}
                        source="organisation_id"
                        reference="organisation"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <DateField locales={locale} source={"updated"} />
                </Datagrid>
            )}
        </List>
    );
};

export const SiteEdit = (props) => (
    <Edit {...props} actions={<SiteEditActions />}>
        <SimpleForm>
            <TextInput fullWidth source="name" />
            <TextInput fullWidth source="short_name" />
            <ReferenceField source="address_id" reference="address">
                <TextField source="street" />
            </ReferenceField>
            <DateTimeInput source="updated" />
            <ReferenceInput
                source="organisation_id"
                reference="organisation"
                filterToQuery={(searchText) => ({ name: searchText })}
            >
                <AutocompleteInput optionText={"name"} />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

import React, { useState, useCallback } from "react";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField as MTextField,
    Autocomplete,
} from "@mui/material";

const API_KEY = "607dac2a74284d4d856f96c2a1874120";

const AddAddress = (props) => {
    const [open, setOpen] = useState(false);
    const [addressSuggestions, setSuggestions] = useState([]);
    const [address, setAddress] = useState();

    const handleClose = () => {
        setOpen(false);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const getAddresses = (searchTerm) => {
        const url = `https://api.addy.co.nz/search?key=${API_KEY}&max=5&s=${searchTerm}`;
        fetch(url)
            .then((data) => {
                return data.json();
            })
            .then((result) => {
                let addresses = [];
                result.addresses.map((add) => {
                    return addresses.push({
                        id: add.id,
                        label: add.a,
                    });
                });
                setSuggestions(addresses);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleInputChange = (event) => {
        event.persist();
        getAddresses(event.target.value);
    };

    const getGPSCoords = useCallback(() => {
        if (addressSuggestions.length < 1 || !address) {
            return;
        }
        props.loading(true);
        const url = `https://api-nz.addysolutions.com/address/${address.id}?key=${API_KEY}`;
        setOpen(false);
        return fetch(url)
            .then((data) => {
                return data.json();
            })
            .then((result) => {
                props.getAddy(result);
                props.loading(false);
            })
            .catch((e) => {
                console.log(e);
            });
    }, [addressSuggestions, address, props]);

    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen}>
                ADD SITE ADDRESS
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle sx={{ paddingLeft: 20, paddingRight: 20 }}>
                    ADD SITE ADDRESS
                </DialogTitle>
                <DialogContent>
                    <Autocomplete
                        autoFocus
                        onChange={(e, v) => setAddress(v)}
                        margin="dense"
                        id="address"
                        options={addressSuggestions}
                        renderInput={(params) => (
                            <MTextField
                                {...params}
                                onChange={handleInputChange}
                                label="Site Address"
                            />
                        )}
                        fullWidth
                        sx={{ paddingBottom: 4 }}
                        variant="standard"
                        helperText="* Required"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={getGPSCoords}>Submit</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AddAddress;

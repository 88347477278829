import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts/core"; //import all charts
import { PieChart } from "echarts/charts";
import dayjs from "dayjs";
import {
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BrushComponent,
    DataZoomComponent,
} from "echarts/components";

import { CanvasRenderer } from "echarts/renderers";
import { init } from "echarts/core";

//register required components

echarts.use([
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BrushComponent,
    PieChart,
    CanvasRenderer,
    DataZoomComponent,
]);

const TimeUserSpentPieGraph = (props) => {
    const chartRef = useRef(null);
    const [sessionData, setSessionData] = useState();
    const [chartObject, setChartObject] = useState(null);

    useEffect(() => {
        if (!chartObject) {
            const myChart = init(chartRef.current);
            setChartObject(myChart);
        }
    }, [chartObject]);

    useEffect(() => {
        let assetHours = 0;
        let appHours = 0;
        let shopHours = 0;
        let assets = props.data.assets;
        let shop = props.data.shop;
        let app = props.data.app;
        assets.map((session, index) => {
            if (assets[index + 1]) {
                let current = assets[index];
                let next = assets[index + 1];
                if (
                    dayjs(next.time).diff(dayjs(current.time), "hour", true) < 1
                ) {
                    assetHours += dayjs(next.time).diff(
                        dayjs(current.time),
                        "hour",
                        true
                    );
                } else {
                    // + 10% of 1 hour (6mins) - for when user refreshed url through app activity then went afk for over an hour
                    assetHours += 0.1;
                }
            }
            return assetHours;
        });

        shop.map((session, index) => {
            if (assets[index + 1]) {
                let current = assets[index];
                let next = assets[index + 1];
                if (
                    dayjs(next.time).diff(dayjs(current.time), "hour", true) < 1
                ) {
                    shopHours += dayjs(next.time).diff(
                        dayjs(current.time),
                        "hour",
                        true
                    );
                } else {
                    // + 10% of 1 hour (6mins) - for when user refreshed url through app activity then went afk for over an hour
                    shopHours += 0.1;
                }
            }
            return shopHours;
        });

        app.map((session, index) => {
            if (assets[index + 1]) {
                let current = assets[index];
                let next = assets[index + 1];
                if (
                    dayjs(next.time).diff(dayjs(current.time), "hour", true) < 1
                ) {
                    appHours += dayjs(next.time).diff(
                        dayjs(current.time),
                        "hour",
                        true
                    );
                } else {
                    // + 10% of 1 hour (6mins) - for when user refreshed url through app activity then went afk for over an hour
                    appHours += 0.1;
                }
            }
            return appHours;
        });
        let timeSpent = [
            { value: assetHours.toFixed(1), name: "Assets" },
            { value: appHours.toFixed(1), name: "App" },
            { value: shopHours.toFixed(1), name: "Shop" },
        ];
        setSessionData(timeSpent);
    }, [props.data]);

    useEffect(() => {
        if (!chartRef.current || !chartObject || !sessionData) return;
        let option = {
            title: {
                text: "Referer of a Website",
                subtext: "Fake Data",
                left: "center",
            },
            tooltip: {
                trigger: "item",
            },
            legend: {
                orient: "vertical",
                left: "left",
            },
            series: [
                {
                    name: "Hours spent",
                    type: "pie",
                    radius: "50%",
                    top: "30%",
                    data: sessionData,
                },
            ],
        };

        chartObject.setOption(option);
    }, [chartObject, props, sessionData]);

    return (
        <div
            style={{
                width: props.width,
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div
                style={{
                    textAlign: "center",
                    paddingBottom: "1rem",
                    fontWeight: "bold",
                }}
            >
                App Usage Last 30 Days (Hrs)
            </div>
            <div ref={chartRef} style={{ width: "100%", height: 350 }} />
        </div>
    );
};

export default TimeUserSpentPieGraph;

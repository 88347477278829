import React, { useState, useEffect, useCallback } from "react";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField as MTextField,
    Autocomplete,
} from "@mui/material";
import { gql, useQuery, useMutation } from "@apollo/client";

const QUERY_ALL_DEVICES_WITHOUT_MP = gql`
    query getAllDevicesWithoutMp {
        sab_device(where: { _not: { measurement_point: {} } }) {
            id
            serial_number
        }
    }
`;

const MUTATION_UPDATE_DEVICE_TO_MP = gql`
    mutation updateMpWithDevice($mpId: Int!, $deviceId: Int!) {
        update_measurement_point_by_pk(
            pk_columns: { id: $mpId }
            _set: { device_id: $deviceId }
        ) {
            id
            device_id
        }
    }
`;

const UpdateMeasurementPoint = (props) => {
    const [open, setOpen] = useState(false);
    const [deviceOptions, setDeviceOptions] = useState();
    const [selectedDevice, setSelectedDevice] = useState();

    const [addDeviceToMp] = useMutation(MUTATION_UPDATE_DEVICE_TO_MP);

    const { data: allDevices, error: devicesError } = useQuery(
        QUERY_ALL_DEVICES_WITHOUT_MP,
        {
            onError: () => console.log(devicesError),
        }
    );

    useEffect(() => {
        if (!allDevices) return;
        let devices = [];
        allDevices.sab_device.map((device) => {
            return devices.push({
                value: device.id,
                label: device.serial_number,
            });
        });
        setDeviceOptions(devices);
    }, [allDevices]);

    const handleClose = () => {
        setOpen(false);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleSubmit = useCallback(() => {
        addDeviceToMp({
            variables: {
                deviceId: selectedDevice.value,
                mpId: props.mp,
            },
        }).then((r) => {
            console.log(r);
            props.refetch();
            setOpen(false);
        });
    }, [addDeviceToMp, props, selectedDevice]);

    return (
        <div style={{}}>
            <Button variant="outlined" onClick={handleClickOpen}>
                No Device Attached, Add A Device
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>SELECT DEVICE TO ADD</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        id="device"
                        onChange={(e, v) => setSelectedDevice(v)}
                        options={deviceOptions}
                        renderInput={(params) => (
                            <MTextField {...params} label="Existing Devices" />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    {selectedDevice && (
                        <Button onClick={handleSubmit}>Submit</Button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default UpdateMeasurementPoint;

import { AmplifyTheme } from "aws-amplify-react";

export const authTheme = {
    ...AmplifyTheme,
    sectionFooterSecondaryContent: {
        display: 'none'
    },
    hint: {
        display: 'none'
    },
    errorSection: {
        ...AmplifyTheme.errorSection,
        backgroundColor: '#2e3b55',
        color: 'white',
    },
    button: {
        ...AmplifyTheme.button,
        backgroundColor: '#2e3b55',
        color: 'white',
        width: '100%',
        margin: 0
    },
    inputLabel: {
        display: 'none'
    },
    input: {
        ...AmplifyTheme.input,
        marginBottom: 10
    },
    formContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    formSection: {
        width: '300px',
        padding: 30,
        backgroundColor: 'white',
        borderRadius: 10
    },
    sectionHeader: {
        ...AmplifyTheme.sectionHeader,
        backgroundColor: '#2e3b55'
    }
}
import React, { useState, useEffect, useCallback } from "react";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField as MTextField,
    Autocomplete,
} from "@mui/material";
import { useNotify } from "react-admin";
import AddDevice from "./AddDevice";
import { gql, useQuery, useMutation } from "@apollo/client";
import dayjs from "dayjs";

const DEVICE_TYPES = [
    { value: "Celsor", label: "Celsor" },
    { value: "MeterReader", label: "MeterReader" },
    { value: "PowerReader", label: "PowerReader" },
    { value: "DoorInformer", label: "DoorInformer" },
    { value: "SpaceMon", label: "SpaceMon" },
    { value: "HVAC", label: "HVAC" },
    { value: "HeadTeller", label: "HeadTeller" },
    { value: "WaterMon", label: "WaterMon" },
    { value: "TemperatureBeacon", label: "TemperatureBeacon" },
    { value: "MagBeacon", label: "MagBeacon" },
    { value: "WASHWATCH", label: "WASHWATCH" },
    { value: "VAC", label: "VAC" },
];

const QUERY_ALL_DEVICES_AND_SITE_ZONES = gql`
    query getAllDevicesZones($siteId: Int!) {
        sab_device(where: { _not: { measurement_point: { device_id: {} } } }) {
            id
            serial_number
        }
        site_by_pk(id: $siteId) {
            id
            zones {
                id
                name
            }
        }
    }
`;

const QUERY_SITE_LAT_LONG = gql`
    query getSiteLatLong($siteId: Int!) {
        address(where: { site: { id: { _eq: $siteId } } }) {
            id
            latitude
            longitude
        }
    }
`;

const MUTATION_INSERT_NEW_MP = gql`
    mutation insertNewMp($mpObject: measurement_point_insert_input = {}) {
        insert_measurement_point_one(object: $mpObject) {
            id
            name
            device_type
            created
        }
    }
`;

const AddMeasurementPoint = (props) => {
    const notify = useNotify();
    const [open, setOpen] = useState(false);
    const [newDevice, setNewDevice] = useState();
    const [deviceOptions, setDeviceOptions] = useState();
    const [mpName, setMpName] = useState("");
    const [selectedDeviceType, setSelectedDeviceType] = useState();
    const [selectedDevice, setSelectedDevice] = useState();
    const [siteCoords, setSiteCoords] = useState();

    const [insertMeasurementPoint] = useMutation(MUTATION_INSERT_NEW_MP);

    const {
        data: addMpData,
        error: AddMpQueryError,
        refetch: refetchDevices,
    } = useQuery(QUERY_ALL_DEVICES_AND_SITE_ZONES, {
        skip: !props.siteId,
        variables: { siteId: props.siteId },
        onError: () => console.log(AddMpQueryError),
    });

    const { data: latLongData, error: latLongError } = useQuery(
        QUERY_SITE_LAT_LONG,
        {
            skip: !props.siteId,
            variables: { siteId: props.siteId },
            onError: () => console.log(latLongError),
        }
    );

    useEffect(() => {
        if (!latLongData || !latLongData.address[0]) return;
        setSiteCoords({
            lat: latLongData.address[0].latitude,
            long: latLongData.address[0].longitude,
        });
    }, [latLongData]);

    useEffect(() => {
        if (!addMpData) return;
        let devices = [];
        let zones = [];
        addMpData.sab_device.map((device) => {
            return devices.push({
                label: device.serial_number,
                id: device.id,
            });
        });
        addMpData.site_by_pk.zones.map((zone) => {
            return zones.push({
                label: zone.name,
                id: zone.id,
            });
        });
        setDeviceOptions(devices);
    }, [addMpData]);

    useEffect(() => {
        if (!newDevice) return;
        setSelectedDevice(newDevice);
    }, [newDevice]);

    const handleClose = () => {
        setOpen(false);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleSubmit = useCallback(() => {
        if (!selectedDevice && !newDevice) {
            notify(
                "Please select select an existing device or create a new device"
            );
        }
        if (!selectedDeviceType) notify("Please select a device type");
        let newMp = {
            created: dayjs().format(),
            updated: dayjs().format(),
            name: mpName,
            device_id: selectedDevice ? selectedDevice.id : newDevice.id,
            zone_id: props.zone,
            device_type: selectedDeviceType.value,
            needs_config_save: false,
            latitude: siteCoords ? siteCoords.lat : 0,
            longitude: siteCoords ? siteCoords.long : 0,
        };
        insertMeasurementPoint({
            variables: {
                mpObject: newMp,
            },
        })
            .then((r) => {
                console.log(r);
                notify(
                    `Measurement Point: ${r.data.insert_measurement_point_one.name} added`
                );
                setOpen(false);
                if (props.addMp) {
                    props.addMp(r.data.insert_measurement_point_one);
                }
            })
            .catch((e) => {
                console.log(e);
                notify(`Error adding measurement point: ${e}`);
            });
    }, [
        mpName,
        selectedDevice,
        newDevice,
        props,
        siteCoords,
        selectedDeviceType,
        insertMeasurementPoint,
        notify,
    ]);

    const handleInputChange = (event) => {
        event.persist();
        setMpName(event.target.value);
    };

    // if (!deviceOptions || !zoneOptions) return <div></div>;
    return (
        <div>
            <Button
                variant="outlined"
                onClick={handleClickOpen}
                disabled={!props.orgId || !props.siteId || !props.zone}
            >
                ADD NEW MEASUREMENT POINT
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>ADD MEASUREMENT POINT</DialogTitle>
                <DialogContent>
                    <MTextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Measurement Point Name"
                        onChange={handleInputChange}
                        fullWidth
                        sx={{ paddingBottom: 4 }}
                        variant="standard"
                        helperText="* Required"
                    />
                    {!newDevice && deviceOptions && (
                        <Autocomplete
                            id="device"
                            sx={{ paddingBottom: 2 }}
                            options={deviceOptions}
                            onChange={(e, v) => setSelectedDevice(v)}
                            renderInput={(params) => (
                                <MTextField
                                    {...params}
                                    label="Existing Device"
                                />
                            )}
                        />
                    )}
                    {newDevice && deviceOptions && (
                        <Autocomplete
                            id="device"
                            sx={{ paddingBottom: 2 }}
                            options={deviceOptions}
                            defaultValue={newDevice}
                            onChange={(e, v) => setSelectedDevice(v)}
                            renderInput={(params) => (
                                <MTextField
                                    {...params}
                                    label="Existing Device"
                                />
                            )}
                        />
                    )}
                    <div>OR</div>
                    <AddDevice
                        orgId={props.orgId}
                        setDevice={setNewDevice}
                        refetch={refetchDevices}
                    />
                    <Autocomplete
                        id="deviceType"
                        sx={{ paddingTop: 2 }}
                        onChange={(e, v) => setSelectedDeviceType(v)}
                        options={DEVICE_TYPES}
                        renderInput={(params) => (
                            <MTextField
                                {...params}
                                label="Device Type"
                                helperText="* Required"
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        disabled={mpName === "" || !selectedDeviceType}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AddMeasurementPoint;

import * as React from "react";
import { useEffect, useState } from "react";
import { Admin, Loading, Resource, Layout } from "react-admin";
import { Amplify } from "aws-amplify";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import buildDataProvider from "ra-data-hasura";
import {
    OrganisationCreate,
    OrganisationEdit,
    OrganisationList,
    OrganisationShow,
} from "./resources/Organisation";
import { AssetList, AssetEdit, AssetCreate } from "./resources/Asset";
import buildVariables from "./buildVariables";
import responseParser from "./responseParser";
import { SiteCreate, SiteEdit, SiteList, SiteShow } from "./resources/Site";
import { ZoneEdit, ZoneList } from "./resources/Zone";
import { UserList, UserEdit, UserShow } from "./resources/User";
import authProvider from "./authProvider";
import {
    Person,
    Apartment,
    LocationOn,
    Policy,
    Devices,
    Timer,
    Subscriptions,
    Home,
    Group,
    MeetingRoom,
    Adjust,
} from "@material-ui/icons";
import CancelIcon from "@material-ui/icons/Cancel";
import { Auth } from "@aws-amplify/auth";
import { decode } from "base-64";
import { Button, Card } from "@material-ui/core";
import { withAuthenticator } from "aws-amplify-react";
import { authTheme } from "./styles";
import { SubscriptionList } from "./resources/Subscription";
import { UserGroupList } from "./resources/UserGroup";
import { AlertRosterList } from "./resources/AlertRoster";
import { PolicyList } from "./resources/Policy";
import { DeviceCreate, DeviceList } from "./resources/Device";
import { AddressList } from "./resources/Address";
import MyAppBar from "./components/AppBar";
import {
    MeasurementPointCreate,
    MeasurementPointList,
    MeasurementPointShow,
} from "./resources/MeasurementPoint";

// configure amplify
Amplify.configure({
    Auth: {
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    },
    Analytics: {
        disabled: true,
    },
});

const createClient = async (authToken) => {
    return new ApolloClient({
        uri: process.env.REACT_APP_API_URL + '/graphql',
        cache: new InMemoryCache(),
        headers: {
            Authorization: `Bearer ${encodeURI(authToken)}`,
            // uncomment for full access
            //'x-hasura-admin-secret': encodeURI(process.env.REACT_APP_HASURA_SECRET_DEV),
        },
    });
};

const decodeTokenPayload = (token) => {
    let base64 = token.split(".")[1];
    return JSON.parse(decode(base64));
};

const MyLayout = (props) => <Layout {...props} appBar={MyAppBar} />;

const App = () => {
    const [dataProvider, setDataProvider] = useState(null);
    const [access, setAccess] = useState(false);
    const [graphQLClient, setGraphQLClient] = useState(null);

    useEffect(() => {
        if (!graphQLClient) return;
        const build = async () => {
            const dataProvider = await buildDataProvider({
                client: graphQLClient,
            });
            setDataProvider(() => dataProvider);
            buildVariables();
            responseParser();
        };
        build();
    }, [graphQLClient]);

    useEffect(() => {
        Auth.currentSession().then((session) => {
            console.log(session);
            let groups = decodeTokenPayload(session.idToken.getJwtToken())[
                "cognito:groups"
            ];
            let access = -1;
            if (groups !== undefined)
                access = groups.findIndex((a) => a === "admin");
            if (access === -1) setAccess(false);
            else setAccess(true);

            createClient(session.idToken.getJwtToken())
                .then((client) => {
                    setGraphQLClient(client);
                })
                .catch((e) => {
                    console.log("failed to create api client");
                });
        });
    }, []);

    if (!access) {
        return (
            <Card
                style={{
                    height: "100vh",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                }}
            >
                <div style={{ textAlign: "center" }}>
                    <CancelIcon fontSize={"large"} color={"secondary"} />
                    <h2>No admin permission</h2>
                    <p>
                        This account does not have permission to access this
                        page.
                    </p>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                            Auth.signOut({ global: true }).then((r) =>
                                window.location.reload(true)
                            )
                        }
                    >
                        Sign out
                    </Button>
                </div>
            </Card>
        );
    }

    if (!dataProvider || !graphQLClient)
        return (
            <Loading
                loadingPrimary={"BenchMarker"}
                loadingSecondary={"admin portal"}
            />
        );

    return (
        <ApolloProvider client={graphQLClient}>
            <Admin
                dataProvider={dataProvider}
                authProvider={authProvider}
                layout={MyLayout}
            >
                <Resource
                    name="organisation"
                    list={OrganisationList}
                    edit={OrganisationEdit}
                    show={OrganisationShow}
                    create={OrganisationCreate}
                    options={{
                        label: "Organisations",
                    }}
                    icon={Apartment}
                />
                <Resource
                    name="site"
                    list={SiteList}
                    edit={SiteEdit}
                    show={SiteShow}
                    create={SiteCreate}
                    options={{
                        label: "Sites",
                    }}
                    icon={LocationOn}
                />
                <Resource
                    name="zone"
                    list={ZoneList}
                    edit={ZoneEdit}
                    options={{ label: "Zones" }}
                />
                <Resource
                    name="user_profile"
                    list={UserList}
                    edit={UserEdit}
                    show={UserShow}
                    options={{
                        label: "Users",
                    }}
                    icon={Person}
                />
                <Resource
                    name="asset"
                    list={AssetList}
                    edit={AssetEdit}
                    create={AssetCreate}
                    options={{ label: "Assets" }}
                    icon={MeetingRoom}
                />
                <Resource name="asset_type" />
                <Resource name="ppcs_core_assetmake" />
                <Resource
                    name="organisation_subscription"
                    list={SubscriptionList}
                    options={{ label: "Subscriptions" }}
                    icon={Subscriptions}
                />
                <Resource
                    name="ppcs_core_usergroup"
                    list={UserGroupList}
                    options={{ label: "User Groups" }}
                    icon={Group}
                />
                <Resource
                    name="alert_roster"
                    list={AlertRosterList}
                    options={{ label: "Alert Rosters" }}
                    icon={Timer}
                />
                <Resource
                    name="ppcs_core_policy"
                    list={PolicyList}
                    options={{ label: "Policies" }}
                    icon={Policy}
                />
                <Resource
                    name="measurement_point"
                    list={MeasurementPointList}
                    show={MeasurementPointShow}
                    create={MeasurementPointCreate}
                    options={{ label: "Measurement Points" }}
                    icon={Adjust}
                />
                <Resource
                    name="sab_device"
                    list={DeviceList}
                    create={DeviceCreate}
                    options={{ label: "Devices" }}
                    icon={Devices}
                />
                <Resource
                    name="address"
                    list={AddressList}
                    options={{ label: "Addresses" }}
                    icon={Home}
                />
                <Resource name="ppcs_core_policy_users" />
            </Admin>
        </ApolloProvider>
    );
};

export default withAuthenticator(App, {
    theme: authTheme,
    usernameAttributes: "email",
});

import * as React from "react";
import {
    List,
    TextInput,
    Datagrid,
    TextField,
    Filter,
    SimpleList,
    useLocale,
    ReferenceField,
} from "react-admin";

import { useMediaQuery } from "@material-ui/core";

export const AlertRosterList = (props) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const locale = useLocale();

    const AlertRosterFilter = (props) => (
        <Filter {...props}>
            <TextInput label="Search by roster name" source="name" alwaysOn />
        </Filter>
    );

    return (
        <List
            style={{ marginTop: -5, marginLeft: -10, marginRight: -10 }}
            {...props}
            perPage={isSmall ? 8 : 25}
            sort={{ field: "name", order: "DESC" }}
            filters={<AlertRosterFilter />}
        >
            {isSmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(record) => record.name}
                />
            ) : (
                <Datagrid locales={locale} rowClick="show">
                    <ReferenceField
                        link={"show"}
                        source="organisation_id"
                        reference="organisation"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField source={"name"} />
                    <TextField source="service" />
                    <TextField source="start_time" />
                    <TextField source="end_time" />
                </Datagrid>
            )}
        </List>
    );
};

import * as React from "react";
import { AppBar } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { useMediaQuery } from "@material-ui/core";
import flower from "../assets/Svgs/flower.svg";

const STAGING_CLIENT = "7akbsi7opk1eru9vk7mclgepco";
const DEV_CLIENT = "ad71pb92mgefhojurds6upjsj";
const PROD_CLIENT = "7qp0uurj9rhv4g6ff8tuifip7r";

let stage = "DEV";
if (process.env.REACT_APP_COGNITO_CLIENT_ID === DEV_CLIENT) {
    stage = "DEV";
} else if (process.env.REACT_APP_COGNITO_CLIENT_ID === STAGING_CLIENT) {
    stage = "STAGING";
} else if (process.env.REACT_APP_COGNITO_CLIENT_ID === PROD_CLIENT) {
    stage = "PROD";
}

const MyAppBar = (props) => {
    const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));

    return (
        <AppBar
            {...props}
            style={{
                background: "#25213A",
                paddingBottom: 2,
                paddingTop: 2,
            }}
        >
            <Typography
                variant="h6"
                color="inherit"
                id="react-admin-title"
                style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                }}
            />
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "auto",
                }}
            >
                {isXSmall ? (
                    <img
                        alt={"Logo"}
                        src={flower}
                        width={24}
                        style={{ margin: "auto" }}
                    />
                ) : (
                    <img
                        alt={"Logo"}
                        src={flower}
                        width={30}
                        style={{ margin: "auto" }}
                    />
                )}
                <div
                    style={{
                        display: "flex",
                        justifySelf: "center",
                        margin: "auto",
                        paddingLeft: 20,
                        fontSize: isXSmall ? 16 : 22,
                    }}
                >
                    BenchMarker Admin ({stage})
                    <div
                        style={{
                            fontSize: "12px",
                            alignSelf: "end",
                            paddingLeft: "2rem",
                        }}
                    >
                        v{process.env.REACT_APP_VERSION}
                    </div>
                </div>
            </div>
        </AppBar>
    );
};

export default MyAppBar;

import { AUTH_LOGOUT } from "react-admin";
import { Auth } from "@aws-amplify/auth";

const authProvider = (type, params) => {
    if (type === AUTH_LOGOUT) {
        Auth.signOut({ global: true }).then((r) =>
            window.location.reload(true)
        );
    }
    return Promise.resolve();
};

export default authProvider;

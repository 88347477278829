import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts/core"; //import all charts
import { BarChart } from "echarts/charts";
import dayjs from "dayjs";
import {
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BrushComponent,
    DataZoomComponent,
} from "echarts/components";

import { CanvasRenderer } from "echarts/renderers";
import { init } from "echarts/core";

//register required components

echarts.use([
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BrushComponent,
    BarChart,
    CanvasRenderer,
    DataZoomComponent,
]);

const UserLoginBarGraph = (props) => {
    const chartRef = useRef(null);
    const [appData, setAppData] = useState();

    useEffect(() => {
        let assetArr = [];
        let appArr = [];
        let shopArr = [];
        let xAxisArr = [];
        for (let i = 0; i < 31; i++) {
            let assetCount = 0;
            let appCount = 0;
            let shopCount = 0;
            let start = dayjs().subtract(i, "days").startOf("day");
            let end = dayjs().subtract(i, "days").endOf("day");
            xAxisArr.unshift(end.format("DD/MM"));
            let filtered = [];
            props.data.map((value, index) => {
                let current = props.data[index];
                let next = props.data[index + 1] ? props.data[index + 1] : null;
                //needs to be over half a minute apart
                if (
                    next &&
                    dayjs(current.time).diff(dayjs(next.time), "m", true) > 0.5
                ) {
                    return filtered.push(current);
                } else if (next && current.user.id !== next.user.id) {
                    return filtered.push(current);
                } else if (index === props.data.length - 1) {
                    return filtered.push(props.data[index]);
                }
                return filtered;
            });

            filtered.map((login) => {
                if (
                    dayjs(login.time) >= start &&
                    dayjs(login.time) <= end &&
                    login.application === "ASSETS"
                ) {
                    assetCount += 1;
                } else if (
                    dayjs(login.time) >= start &&
                    dayjs(login.time) <= end &&
                    login.application === "APP"
                ) {
                    appCount += 1;
                } else if (
                    dayjs(login.time) >= start &&
                    dayjs(login.time) <= end &&
                    login.application === "SHOP"
                ) {
                    shopCount += 1;
                }
                return null;
            });
            assetArr.unshift(assetCount);
            appArr.unshift(appCount);
            shopArr.unshift(shopCount);
            assetCount = 0;
            appCount = 0;
            shopCount = 0;
            let data = {
                app: appArr,
                assets: assetArr,
                shop: shopArr,
                xAxis: xAxisArr,
            };
            setAppData(data);
        }
    }, [props]);

    const [chartObject, setChartObject] = useState(null);

    useEffect(() => {
        if (!chartObject) {
            const myChart = init(chartRef.current);
            setChartObject(myChart);
        }
    }, [chartObject]);

    useEffect(() => {
        if (!chartRef.current || !chartObject) return;
        let option = {
            tooltip: {},
            legend: {},
            xAxis: {
                type: "category",
                data: appData.xAxis,
                axisLine: { onZero: true, show: true },
                axisTick: { show: false },
                splitLine: { show: false },
                splitArea: { show: false },
                label: { show: false },
                axisLabel: {
                    margin: 16,
                    fontStyle: "bolder",
                },
            },
            yAxis: {
                minInterval: 1,
                axisLine: {
                    show: true,
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: false,
                },
                axisLabel: {
                    margin: 10,
                    fontStyle: "bolder",
                },
            },
            grid: {
                show: false,
            },
            dataZoom: {
                start: 0,
                type: "inside",
            },
            series: [
                {
                    name: "Assets",
                    type: "bar",
                    data: appData.assets,
                },
                {
                    name: "App",
                    type: "bar",
                    data: appData.app,
                },
                { name: "Shop", type: "bar", data: appData.shop },
            ],
        };

        chartObject.setOption(option);
    }, [chartObject, appData, props]);

    return (
        <div
            style={{
                width: props.width,
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div
                style={{
                    textAlign: "center",
                    paddingBottom: "1rem",
                    fontWeight: "bold",
                }}
            >
                Authentications per day
            </div>
            <div ref={chartRef} style={{ width: "100%", height: 350 }} />
        </div>
    );
};

export default UserLoginBarGraph;

import React, { useState, useCallback } from "react";
import {
    Button,
    TextField as MTextField,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
} from "@mui/material";
import { ExpandMore } from "@material-ui/icons";
import { gql, useMutation } from "@apollo/client";
import dayjs from "dayjs";
import { useNotify } from "react-admin";

const MUTATION_ADD_NEW_DEVICE = gql`
    mutation createDevice($deviceObject: sab_device_insert_input = {}) {
        insert_sab_device_one(object: $deviceObject) {
            id
            organisation_id
            dev_eui
            serial_number
        }
    }
`;

const AddDevice = (props) => {
    const [deviceForm, setDeviceForm] = useState({
        serial: "",
        devEui: "",
    });
    const notify = useNotify();
    const [AddNewDevice] = useMutation(MUTATION_ADD_NEW_DEVICE);

    const handleClick = useCallback(() => {
        let newDevice = {
            organisation_id: props.orgId,
            serial_number: deviceForm.serial,
            dev_eui: deviceForm.devEui,
            created: dayjs().format(),
            updated: dayjs().format(),
            packet_count: 1,
        };
        AddNewDevice({
            variables: {
                deviceObject: newDevice,
            },
        })
            .then((r) => {
                notify(
                    `Device added: ${r.data.insert_sab_device_one.serial_number}`
                );
                props.setDevice({
                    label: r.data.insert_sab_device_one.serial_number,
                    id: r.data.insert_sab_device_one.id,
                });
                props.refetch();
            })
            .catch((e) => {
                console.log(e);
                notify(`Error adding device: ${e}`);
            });
    }, [deviceForm, props, notify, AddNewDevice]);

    const handleInputChange = (event) => {
        event.persist();
        setDeviceForm((inputs) => ({
            ...inputs,
            [event.target.id]: event.target.value,
        }));
    };

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ marginTop: 2 }}
            >
                <Typography>Add New Device</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    <MTextField
                        autoFocus
                        margin="dense"
                        id="serial"
                        label="Serial Number"
                        fullWidth
                        sx={{ paddingBottom: 4 }}
                        variant="standard"
                        required={true}
                        onChange={handleInputChange}
                    />
                    <MTextField
                        autoFocus
                        margin="dense"
                        id="devEui"
                        label="Device Eui"
                        fullWidth
                        sx={{ paddingBottom: 4 }}
                        variant="standard"
                        required={true}
                        onChange={handleInputChange}
                    />
                    <div>
                        <Button
                            disabled={
                                deviceForm.serial === "" ||
                                deviceForm.devEui === ""
                            }
                            onClick={handleClick}
                        >
                            Add Device
                        </Button>
                    </div>
                </Typography>
            </AccordionDetails>
        </Accordion>
    );
};

export default AddDevice;

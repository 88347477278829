import React, { useState, useEffect, useCallback } from "react";
import {
    List,
    Create,
    Edit,
    SimpleForm,
    AutocompleteInput,
    ListButton,
    ShowButton,
    TextInput,
    Datagrid,
    TextField,
    TopToolbar,
    Filter,
    SimpleList,
    useLocale,
    ReferenceField,
    ReferenceInput,
    EmailField,
    BooleanField,
    TabbedShowLayout,
    TabbedShowLayoutTabs,
    Show,
    Tab,
    required,
    Toolbar,
    useNotify,
    useRedirect,
} from "react-admin";
import { Add } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { TextField as MTextField, Button } from "@mui/material";
import { useMediaQuery } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import AddMeasurementPoint from "../components/AddMeasurementPoint";
import UpdateMeasurementPoint from "../components/UpdateMeasurementPoint";
import dayjs from "dayjs";

const QUERY_DEVICE_FROM_MP = gql`
    query getDeviceOnMp($mpId: Int!) {
        measurement_point_by_pk(id: $mpId) {
            device {
                id
                serial_number
            }
        }
    }
`;

const QUERY_MP_FROM_ASSET = gql`
    query getAssetMeasurementPoint($assetId: Int!) {
        asset_by_pk(id: $assetId) {
            id
            measurement_points {
                id
                measurement_point {
                    id
                    name
                }
            }
        }
    }
`;

const MUTATION_INSERT_ASSET = gql`
    mutation insertAsset(
        $assetTypeId: Int!
        $created: timestamptz!
        $description: String!
        $mpId: Int!
        $siteId: Int!
        $zoneId: Int!
        $orgId: Int!
        $model: String
        $make: String
        $year: Int!
    ) {
        insert_asset_one(
            object: {
                asset_type_id: $assetTypeId
                created: $created
                description: $description
                measurement_points: { data: { measurementpoint_id: $mpId } }
                site_id: $siteId
                zone_id: $zoneId
                organisation_id: $orgId
                model: $model
                make: $make
                year: $year
            }
        ) {
            id
            created
            description
            asset_type_id
            organisation_id
            site_id
            zone_id
            measurement_points {
                measurementpoint_id
            }
        }
    }
`;

export const AssetCreate = (props, { record }) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const [org, setOrg] = useState();
    const [site, setSite] = useState();
    const [zone, setZone] = useState();
    const [MP, setMP] = useState();
    const [measurementPointId, setMeasurementPointId] = useState();
    const [newMeasurementPoint, setNewMeasurementPoint] = useState();
    const [assetType, setAssetType] = useState();
    const [assetForm, setAssetForm] = useState({
        description: "",
        make: "",
        model: "",
        year: "",
    });

    const [addAsset] = useMutation(MUTATION_INSERT_ASSET);

    const [
        getDevice,
        { data: deviceId, error: deviceError, refetch: refetchDevice },
    ] = useLazyQuery(QUERY_DEVICE_FROM_MP, {
        onError: () => console.log(deviceError),
    });

    useEffect(() => {
        if (!deviceId || !deviceId.measurement_point_by_pk) return;
        setMP(deviceId.measurement_point_by_pk);
    }, [deviceId]);

    const PostEditToolbar = () => (
        <Toolbar>
            <Button
                variant="contained"
                onClick={handleClick}
                startIcon={<Add />}
            >
                ADD ASSET
            </Button>
        </Toolbar>
    );

    const handleInputChange = (event) => {
        event.persist();
        setAssetForm((inputs) => ({
            ...inputs,
            [event.target.id]: event.target.value,
        }));
    };

    const handleClick = useCallback(() => {
        addAsset({
            variables: {
                created: dayjs().format(),
                assetTypeId: assetType,
                orgId: org,
                siteId: site,
                zoneId: zone,
                description: assetForm.description,
                mpId: newMeasurementPoint
                    ? newMeasurementPoint.id
                    : measurementPointId,
                make: assetForm.make,
                model: assetForm.model,
                year:
                    assetForm.year === ""
                        ? dayjs().format("YYYY")
                        : assetForm.year,
            },
        })
            .then((r) => {
                notify(
                    `Asset ${r.data.insert_asset_one.description} has been added`
                );
                redirect("/asset");
            })
            .catch((e) => {
                console.log(e);
                notify(`Error adding asset: ${e}`);
            });
    }, [
        addAsset,
        assetType,
        notify,
        redirect,
        assetForm,
        measurementPointId,
        org,
        site,
        zone,
        newMeasurementPoint,
    ]);

    return (
        <Create {...props}>
            <SimpleForm toolbar={<PostEditToolbar />}>
                <MTextField
                    autoFocus
                    margin="dense"
                    id="description"
                    label="Asset Description"
                    onChange={handleInputChange}
                    fullWidth
                    variant="standard"
                    helperText="* Required"
                />
                <ReferenceInput
                    source="organisation_id"
                    fullWidth
                    reference="organisation"
                    validate={[required()]}
                    filterToQuery={(searchText) => ({ name: searchText })}
                    onChange={(e) => setOrg(e)}
                >
                    <AutocompleteInput optionText={"name"} />
                </ReferenceInput>
                <ReferenceInput
                    source="site_id"
                    reference="site"
                    fullWidth
                    validate={[required()]}
                    onChange={(e) => setSite(e)}
                    filter={{ organisation_id: org }}
                    filterToQuery={(searchText) => ({ name: searchText })}
                >
                    <AutocompleteInput optionText={"name"} />
                </ReferenceInput>
                <ReferenceInput
                    source="asset_type_id"
                    reference="asset_type"
                    filter={{ organisation_id: org }}
                    fullWidth
                    validate={[required()]}
                    onChange={(e) => setAssetType(e)}
                    filterToQuery={(searchText) => ({ name: searchText })}
                >
                    <AutocompleteInput optionText={"name"} />
                </ReferenceInput>
                <ReferenceInput
                    source="zone_id"
                    reference="zone"
                    fullWidth
                    filter={{ site_id: site }}
                    validate={[required()]}
                    filterToQuery={(searchText) => ({ name: searchText })}
                    onChange={(e) => setZone(e)}
                >
                    <AutocompleteInput optionText={"name"} />
                </ReferenceInput>
                {!newMeasurementPoint && (
                    <ReferenceInput
                        source="measurement_point_id"
                        reference="measurement_point"
                        fullWidth
                        label="Select existing Measurement point"
                        filter={{ zone_id: zone }}
                        validate={[required()]}
                        onChange={(e) => {
                            setMeasurementPointId(e);
                            getDevice({
                                variables: {
                                    mpId: e,
                                },
                            });
                        }}
                        filterToQuery={(searchText) => ({ name: searchText })}
                    >
                        <AutocompleteInput optionText={"name"} />
                    </ReferenceInput>
                )}
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        columnGap: "2rem",
                    }}
                >
                    <TextInput
                        id="make"
                        source="make"
                        onChange={handleInputChange}
                    />
                    <TextInput
                        id="model"
                        source="model"
                        onChange={handleInputChange}
                    />
                    <TextInput
                        id="year"
                        source="year"
                        onChange={handleInputChange}
                        defaultValue={dayjs().format("YYYY")}
                    />
                </div>
                {newMeasurementPoint && (
                    <div>Measurement Point: {newMeasurementPoint.name}</div>
                )}
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        columnGap: "1rem",
                    }}
                >
                    {MP && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                columnGap: "1rem",
                            }}
                        >
                            <div>DEVICE:</div>
                            {MP.device ? (
                                MP.device.serial_number
                            ) : (
                                <UpdateMeasurementPoint
                                    refetch={refetchDevice}
                                    mp={measurementPointId}
                                />
                            )}
                            <div>OR</div>
                        </div>
                    )}
                    {!newMeasurementPoint && (
                        <AddMeasurementPoint
                            orgId={org}
                            siteId={site}
                            zone={zone}
                            addMp={setNewMeasurementPoint}
                        />
                    )}
                </div>
            </SimpleForm>
        </Create>
    );
};

const AssetEditActions = ({ basePath, data }) => (
    <TopToolbar>
        <ListButton basePath={basePath} label="Back" icon={<ChevronLeft />} />
        <ShowButton basePath={basePath} record={data} />
    </TopToolbar>
);

export const AssetEdit = (props) => {
    const [measurementPoint, setMeasurementPoint] = useState();
    const { data: assetMp, error: assetMpError } = useQuery(
        QUERY_MP_FROM_ASSET,
        {
            variables: {
                assetId: parseInt(props.id),
            },
            onError: () => console.log(assetMpError),
        }
    );

    useEffect(() => {
        if (!assetMp || !assetMp.asset_by_pk.measurement_points[0]) return;
        setMeasurementPoint(
            assetMp.asset_by_pk.measurement_points[0].measurement_point
        );
    }, [assetMp]);
    return (
        <Edit {...props} actions={<AssetEditActions />}>
            <SimpleForm>
                <TextInput fullWidth source="description" />
                <ReferenceInput
                    source="organisation_id"
                    reference="organisation"
                    filterToQuery={(searchText) => ({ name: searchText })}
                >
                    <AutocompleteInput optionText={"name"} />
                </ReferenceInput>
                <ReferenceInput
                    source="site_id"
                    reference="site"
                    filterToQuery={(searchText) => ({ name: searchText })}
                >
                    <AutocompleteInput optionText={"name"} />
                </ReferenceInput>
                <ReferenceInput
                    source="zone_id"
                    reference="zone"
                    filterToQuery={(searchText) => ({ name: searchText })}
                >
                    <AutocompleteInput optionText={"name"} />
                </ReferenceInput>
                <ReferenceInput
                    source="asset_type_id"
                    reference="asset_type"
                    filterToQuery={(searchText) => ({ name: searchText })}
                >
                    <AutocompleteInput optionText={"name"} />
                </ReferenceInput>
                <div>
                    Measurement Point:{" "}
                    {measurementPoint ? (
                        <Link
                            to={`/measurement_point/${measurementPoint.id}/show`}
                        >
                            {measurementPoint.name}
                        </Link>
                    ) : (
                        "Asset has no Measurement Point"
                    )}
                </div>
            </SimpleForm>
        </Edit>
    );
};

export const AssetShow = (props) => {
    return (
        <Show {...props}>
            <TabbedShowLayout
                tabs={<TabbedShowLayoutTabs variant="scrollable" {...props} />}
            >
                <Tab label="summary">
                    <TextField source="name" />
                    <TextField source="short_name" />
                    <TextField source="logo_image_filename" />
                    <TextField source="theme" />
                    <EmailField source="super_admin" />
                    <BooleanField source="slack_issue_notifications" />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export const AssetList = (props) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const locale = useLocale();

    const AssetFilter = (props) => (
        <Filter {...props}>
            <TextInput
                label="Search by asset description"
                source="description"
                alwaysOn
            />
        </Filter>
    );

    return (
        <List
            style={{ marginTop: -5, marginLeft: -10, marginRight: -10 }}
            {...props}
            perPage={isSmall ? 8 : 25}
            sort={{ field: "description", order: "DESC" }}
            filters={<AssetFilter />}
        >
            {isSmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(record) => record.serial_description}
                    secondaryText={(record) => `${record.site.name}`}
                />
            ) : (
                <Datagrid locales={locale} rowClick="show">
                    <TextField source={"description"} />
                    <ReferenceField
                        link={false}
                        source="asset_type_id"
                        reference="asset_type"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField
                        link={"show"}
                        source="organisation_id"
                        reference="organisation"
                    >
                        <TextField source="name" />
                    </ReferenceField>

                    <ReferenceField
                        link={"show"}
                        source="site_id"
                        reference="site"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField
                        link={"show"}
                        source="zone_id"
                        reference="zone"
                    >
                        <TextField source="name" />
                    </ReferenceField>

                    <TextField source="serial_number" />
                    <ReferenceField
                        link={false}
                        source="asset_make_id"
                        reference="ppcs_core_assetmake"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField source="make" />
                    <TextField source="model" />
                    <TextField source="year" />
                </Datagrid>
            )}
        </List>
    );
};

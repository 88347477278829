import * as React from "react";
import {
    List,
    Edit,
    SimpleForm,
    TextInput,
    Datagrid,
    TextField,
    useLocale,
    TopToolbar,
    ShowButton,
    Filter,
    ReferenceField,
    SimpleList,
    ReferenceInput,
    AutocompleteInput,
} from "react-admin";

import { DateTimeInput, ListButton } from "ra-ui-materialui";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { useMediaQuery } from "@material-ui/core";
import { Cancel, CheckCircle } from "@material-ui/icons";

const ZoneEditActions = ({ basePath, data }) => (
    <TopToolbar>
        <ListButton basePath={basePath} label="Back" icon={<ChevronLeft />} />
        <ShowButton basePath={basePath} record={data} />
    </TopToolbar>
);

const ZoneFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search by site name" source="name" alwaysOn />
    </Filter>
);

export const ZoneList = (props) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const locale = useLocale();

    return (
        <List
            style={{ marginTop: -5, marginLeft: -10, marginRight: -10 }}
            {...props}
            perPage={isSmall ? 8 : 25}
            sort={{ field: "name", order: "DESC" }}
            filters={<ZoneFilter />}
        >
            {isSmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(record) => record.id}
                    secondaryText={(record) => `${record.name}`}
                    tertiaryText={(record) =>
                        record.active ? <CheckCircle /> : <Cancel />
                    }
                />
            ) : (
                <Datagrid locales={locale} rowClick="show">
                    <TextField source={"name"} />
                    <TextField source={"short_name"} />
                    <ReferenceField
                        link={"show"}
                        source="site_id"
                        reference="site"
                    >
                        <TextField source={"name"} />
                    </ReferenceField>
                </Datagrid>
            )}
        </List>
    );
};

export const ZoneEdit = (props) => (
    <Edit {...props} actions={<ZoneEditActions />}>
        <SimpleForm>
            <TextInput fullWidth source="name" />
            <TextInput fullWidth source="short_name" />
            <DateTimeInput source="updated" />
            <ReferenceInput
                source="site_id"
                reference="site"
                filterToQuery={(searchText) => ({ name: searchText })}
            >
                <AutocompleteInput optionText={"name"} />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

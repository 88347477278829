import React, { useState, useCallback } from "react";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField as MTextField,
} from "@mui/material";

const AddZone = (props) => {
    const [open, setOpen] = useState(false);

    const [inputForm, setInputForm] = useState({
        name: "",
        shortName: "",
    });

    const handleClose = () => {
        setOpen(false);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleInputChange = (event) => {
        event.persist();
        setInputForm((inputs) => ({
            ...inputs,
            [event.target.id]: event.target.value,
        }));
    };

    const handleSubmit = useCallback(() => {
        let zone = {
            name: inputForm.name,
            short_name: inputForm.shortName,
        };
        props.setZone(zone);
        setOpen(false);
    }, [inputForm, props]);
    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen}>
                ADD ZONE TO SITE
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle sx={{ paddingLeft: 20, paddingRight: 20 }}>
                    Add Zone
                </DialogTitle>
                <DialogContent>
                    <MTextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Zone Name"
                        fullWidth
                        sx={{ paddingBottom: 4 }}
                        variant="standard"
                        required={true}
                        onChange={handleInputChange}
                    />
                    <MTextField
                        autoFocus
                        margin="dense"
                        id="shortName"
                        label="Short Name"
                        fullWidth
                        sx={{ paddingBottom: 4 }}
                        variant="standard"
                        required={true}
                        onChange={handleInputChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        disabled={
                            inputForm.name === "" || inputForm.shortName === ""
                        }
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AddZone;
